<template>
  <div class="animated-cards">
    <div class="animated-cards__background" />
    <Transition mode="out-in">
      <div
        v-if="activeCard"
        :key="activeCard.id"
        class="animated-card flex is-align-center"
      >
        <!-- Blocco Counter-->
        <div v-if="props.cards.length > 1" class="animated-card-counter">
          {{ activeIndex + 1 }} / {{ props.cards.length }}
        </div>

        <!-- Blocco Img -->
        <div
          v-if="activeCard.cardImage"
          class="animated-card-img curved-border"
        >
          <WidgetPicture
            :image="{ custom: activeCard.cardImage.responsiveImage }"
            :alt="activeCard.name"
          />
        </div>

        <!-- Blocco text -->
        <div class="animated-card-text">
          <p
            v-if="activeCard.startDate"
            class="animated-card-date text-sans-xs"
          >
            {{ parsedDate(activeCard.startDate) }}
          </p>
          <p
            v-if="activeCard.name"
            class="animated-card-title text-corpo-m m-top-32px"
          >
            {{ activeCard.name }}
          </p>

          <WidgetArrowLink
            class="m-top-m-1 animated-card-link"
            :label="$t('cta.discover')"
            :link="
              localePath({
                name: 'event-slug',
                params: { slug: activeCard.slug },
              })
            "
            :is-secondary="true"
          />
        </div>
      </div>
    </Transition>

    <!-- Close Btn -->
    <div
      data-no-cursor
      class="animated-cards-close opacity-fader"
      @click="handleCardsClose"
    >
      <img
        width="16"
        height="16"
        src="@/assets/img/ico/ico-close-black.svg"
        ref="closeButton"
        alt="Close"
        class=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetAnimatedCards',
}
</script>

<script setup>
import { gsap } from 'gsap'

const { locale, locales } = useI18n()
const emit = defineEmits(['close-cards'])

const props = defineProps({
  cards: {
    type: Array,
    default: () => [],
  },
})

const activeIndex = ref(0)
const activeCard = computed(() => props.cards[activeIndex.value])
const handleCardsClose = () => emit('close-cards')

const parsedDate = (rawDate) => {
  if (!rawDate) return ''
  const event = new Date(rawDate)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const actualLocaleObj = locales.value.find(
    (localeObj) => localeObj.code === locale.value,
  )
  return event.toLocaleDateString(actualLocaleObj?.language, options)
}

const startAnimation = () => {
  gsap.to('.animated-cards__background', {
    width: '100%', // Animate the background to cover the entire div
    duration: 7, // Duration of the animation in seconds
    delay: 0.5, // Delay before the animation starts (1 seconds)
    ease: 'power2.inOut', // Smooth easing for the animation
    onComplete: changeCard,
  })
}

const changeCard = () => {
  const nextIndex = activeIndex.value + 1
  if (nextIndex >= props.cards.length) {
    activeIndex.value = 0
  } else {
    activeIndex.value = activeIndex.value + 1
  }

  // Re-trigger the background animation and content change after 4 seconds
  // ...if there are some cards..
  if (!document?.querySelector('.animated-cards__background')) return

  gsap.to('.animated-cards__background', {
    width: '0%', // Reset the background width
    duration: 0,
    onComplete: () => {
      gsap.to('.animated-cards__background', {
        width: '100%',
        duration: 7,
        delay: 0.5,
        ease: 'power2.inOut',
        onComplete: changeCard, // Call the function recursively for continuous changes
      })
    },
  })
}

onMounted(() => {
  if (props.cards.length > 1) {
    startAnimation()
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
