<template>
  <div
    data-no-cursor
    class="floating-book"
    :class="{ '--hidden': isHidden }"
    :aria-label="$t('cta.book')"
  >
    <NuxtLink class="button-round-1-blue" :to="localePath({ name: 'prenota' })">
      <span>{{ $t('cta.book') }}</span>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: 'WidgetFloatingBook',
}
</script>

<script setup>
const route = useRoute()
const lenis = inject('lenis')

const touch = ref(false)
const observerInstance = ref(null)
const lastScrollPosition = ref(0)
const scrollThreshold = ref(1000)
const lightScrollTop = setLightScrollTop()
const hideScrollTop = setHideScrollTop()
const isHidden = computed(() => footerVisible.value)
const observer = ref(null)
const footerVisible = ref(false)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints

  setTimeout(() => {
    // agganciamo l'istanza dell'observer sul Footer
    const footerMain = document.querySelector('.footer-main')
    const isFooterFixed = footerMain.classList.contains('--fixed')
    const footerTarget = isFooterFixed
      ? '.footer-main-placeholder'
      : '.footer-main'
    const footerEl = document.querySelector(footerTarget)

    observer.value = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            footerVisible.value = true
          } else {
            footerVisible.value = false
          }
        })
      },
      {
        threshold: 0.1,
      },
    )

    observer.value?.observe(footerEl)
  }, 1000)
})

onUnmounted(() => {
  // Disconnect the observer when the component unmounts
  if (observer.value) {
    observer.value.disconnect()
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
