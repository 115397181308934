<template>
  <div
    class="animated-cards-container"
    :class="{ '--off': closedCards || !showableCards || !cards.length }"
  >
    <Transition>
      <div class="animated-cards-wrap" v-if="showableCards && cards?.length">
        <WidgetAnimatedCards :cards="cards" @close-cards="handleCardsClose" />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'WidgetAnimatedCardsContainer',
}
</script>

<script setup>
import { getHomeCards } from '@/graphql/dato/queries/home.js'

const { locale } = useI18n()

const closedCards = ref(false)
const hasUserScrolled = ref(false)
const hasTimeoutPassed = ref(false)

const matchDesktop = useHelpers().evalMatchMedia('(min-width: 1000px)')

// Cards data queries (from DatoCms)
const variables = computed(() => ({ locale: locale.value }))
const { data } = await useGraphql(getHomeCards, variables)
if (!data.value.home) {
  throw createError({ statusCode: 404 })
}
const cards = computed(() => {
  const allCards = data?.value?.home?.hero ? data?.value?.home.hero.cards : []
  const stillValidCards = allCards.filter((card) => {
    var userEntered = new Date(card.startDate) // valueAsNumber has no time or timezone!
    var now = new Date()
    var today = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
    )
    if (userEntered.getTime() >= today.getTime()) {
      return card
    }
  })

  return stillValidCards
})

const handleCardsClose = () => {
  closedCards.value = true
}

const handleScroll = (e) => {
  if (matchDesktop.value) return
  const scrollThreshold = 1500

  if (window.scrollY > scrollThreshold) {
    hasUserScrolled.value = true
  }
}

const showableCards = computed(() => {
  return matchDesktop.value
    ? !closedCards.value && hasTimeoutPassed.value
    : !closedCards.value && hasUserScrolled.value
})

onMounted(() => {
  setTimeout(() => {
    hasTimeoutPassed.value = cards.value?.length
  }, 5000)

  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
@import './style.scss';
</style>
