<template>
  <div v-element-sizes="{ id: 'window' }" class="layout-default">
    <!-- <WidgetMouseCursor /> -->
    <SeoTemplatePreset>
      <NavigationMenu />
      <main>
        <slot />
      </main>

      <FooterMain />

      <WidgetFloatingBook v-if="!hidePrenotaCta" />
      <ListingProjectBlockFullscreenOverlay />
      <WidgetFullScreenVideoOverlay />
      <WidgetAnimatedCardsContainer v-if="isHomepage" />
    </SeoTemplatePreset>
  </div>
</template>

<script setup>
const route = useRoute()
const fromError = setFromError()

const hidePrenotaCta = computed(() => {
  const excludedRoutes = [
    '/prenota',
    '/book-online',
    '/contacts',
    '/contatti',
    '/lavora-con-noi',
    '/contatti',
    '/event',
  ]
  return excludedRoutes.find((routeString) => route.path.includes(routeString))
})

const isHomepage = computed(() => route.path === '/' || route.path === '/en')
</script>
