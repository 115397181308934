<template>
  <WidgetOverlayFullScreen
    data-no-cursor
    class="full-screen-video-overlay"
    ref="blockOverlay"
    :no-bg-animation="true"
    v-if="item"
  >
    <!-- Close Button -->
    <button
      ref="magnetic"
      class="full-screen-video-overlay__close"
      @click="animateOut"
    >
      <svg viewBox="0 0 64 64" class="close-icon">
        <defs>
          <mask id="closeMask">
            <rect width="64" height="64" fill="white" />
            <path
              d="M20 20 L44 44 M44 20 L20 44"
              stroke="black"
              stroke-width="5"
              stroke-linecap="butt"
            />
          </mask>
        </defs>
        <circle cx="32" cy="32" r="30" fill="#39bfb7" mask="url(#closeMask)" />
      </svg>
    </button>

    <div class="full-screen-video-overlay__content">
      <!-- Video Datocms -->
      <VideoPlayer
        v-if="item.video"
        ref="vueplayer"
        :data="item.video"
        :loop="false"
      />

      <!-- Video -->
      <WidgetVideo
        v-if="false"
        ref="blockOverlayVideo"
        class="full-screen-video-overlay__video is-cover"
        :videoUrl="item"
        :controls="true"
        :loop="false"
        :autoplay="false"
        :muted="false"
      />
      <div class="rotate-device-image">
        <img
          src="@/assets/img/ico/ico-rotate-white.svg"
          width="58"
          height="55"
          :alt="$t('cta.rotateDevice')"
        />
      </div>
    </div>
  </WidgetOverlayFullScreen>
</template>

<script>
export default {
  name: 'WidgetFullScreenVideoOverlay',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { VideoPlayer } from 'vue-datocms'

const item = useState('set-video', () => null)

const blockOverlay = ref(null)
const blockOverlayVideo = ref(null)
const magnetic = ref(null)
const vueplayer = ref(null)
const durationSlow = 1

const xTo = ref(null)
const yTo = ref(null)

watch(
  () => item.value,
  async (item) => {
    if (item) {
      await nextTick()
      await animateIn()
      await nextTick()
      addMagneticEffect()
    }
  },
)

const animateIn = async () => {
  await blockOverlay.value.animateVideoIn().duration(durationSlow).play()
  vueplayer.value = document.querySelector('mux-player')
  vueplayer.value?.play()
}

const animateOut = async () => {
  vueplayer.value = document.querySelector('mux-player')
  vueplayer.value?.pause()

  // blockOverlayVideo.value.videoElement.pause()
  await blockOverlay.value.animateVideoOut().duration(durationSlow).play()
  item.value = null
}

const handleMouseMove = (e) => {
  if (!magnetic.value) return
  const rect = magnetic.value.getBoundingClientRect()
  const offsetX = e.clientX - (rect.left + rect.width / 2)
  const offsetY = e.clientY - (rect.top + rect.height / 2)
  const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2)

  if (distance < 200) {
    // Adjust this value to control the distance at which the magnetic effect starts
    const x = offsetX * 0.3
    const y = offsetY * 0.3
    xTo.value(x)
    yTo.value(y)
  } else {
    xTo.value(0)
    yTo.value(0)
  }
}

const handleMouseLeave = () => {
  xTo.value(0)
  yTo.value(0)
}

const addMagneticEffect = () => {
  if (!magnetic.value) return

  xTo.value = gsap.quickTo(magnetic.value, 'x', {
    duration: 1,
    ease: 'elastic.out(1, 0.3)',
  })
  yTo.value = gsap.quickTo(magnetic.value, 'y', {
    duration: 1,
    ease: 'elastic.out(1, 0.3)',
  })

  document.addEventListener('mousemove', handleMouseMove)
  magnetic.value.addEventListener('mouseleave', handleMouseLeave)
}

onMounted(async () => {
  await nextTick()
  addMagneticEffect()
})

onUnmounted(() => {
  document.removeEventListener('mousemove', handleMouseMove)
  magnetic?.value.removeEventListener('mouseleave', handleMouseLeave)
})
</script>

<style lang="scss">
@import './style.scss';
</style>
